<template>
        <div class="list-wrap">
            <div :style="{ 'backgroundImage': `url(${'/oss'+info.smallImgPath})`, 'width': width, 'height': height, 'backgroundSize':'cover', 'background-repeat':'no-repeat', 'background-position':'center' }"></div>
            <div class="list-right">
                <span class="list-right-time">{{ formatTime(info.createDate) }}</span>
                <span class="list-right-title">{{ info.title }}</span>
                <el-button type="text" icon="el-icon-right" class="list-button" @click="goDetails(info)"></el-button>
            </div>
        </div>
</template>
<script>
/* eslint-disable */
import DateUtils from "@/assets/filters/dateUtils";
export default {
    props:['info','height','width'],
    data(){
        return{

        }
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods:{
     formatTime(val){
           return DateUtils.dateFormat(new Date(val)).slice(0,10)
        },
     goDetails(item){
          if(item.wxUrl){
               window.open(item.wxUrl)
               return 
            }
          this.$router.push({
             path:'/newsDetails',
             query:{id:item.id}
            })
         }
    },
}
</script>

<style lang='scss'>
    .list-wrap{
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
    .list-right{
        background: #f3f4f5;
        width: calc(100% - 2rem);
        position:relative;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        padding:0.3rem 0.2rem;
        &-time{
            font-size: 0.12rem;
            color:#909399;
        }
        &-title{
            font-size: 0.14rem;
            color: #606266;
            width: 90%;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
    }
    .list-button{
        position: absolute;
        top:40%;
        right: 6%;
        .el-icon-right{
            font-size: 0.20rem;
            font-weight: 800;
        }
    }
</style>
