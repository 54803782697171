<template>
    <div style="height:100%" class="home">
        <div class="dashborad-swiper">
            <el-carousel :interval="4000" height="8rem" style="position:relative">
                <el-carousel-item v-for="(item,index) in imglist" :key="index">
                    <img :src="item.src" alt="" width="100%" height="100%" fit="fit">
                    <div class="banner-brand">
                        <img class="banner-brand-brand background" :src='item.brand' fit="contain"/><br/>
                        <el-image class="banner-brand-slogan background" :src="item.slogan" fit="contain"/>
                        <div class="banner-brand-content" v-html="item.content"></div>
                        <el-button class="button" :class="{ 'firstBtn': index == 0}" @click="handleJump(item)">了解更多</el-button>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div :style="{'background-size':'cover', 'height':'1.2rem', 'position':'relative'}" class="page_wrap background">
            <div class="page_content flex-between dashborad-introduce" style="background-color: #fff;">
                <div class="dashborad-introduce-item flex-left" v-for="item in introduces" :key="item.index">
                    <img :src="item.url" alt="" style="width: 0.6rem;height: 0.6rem;">
                    <div class="dashborad-introduce-main">
                        <div style="font-size: 0.16rem;color: #0061E3;font-weight: 600;margin-top: 0.06rem;">{{ item.title }}</div>
                        <div style="font-size: 0.12rem;color: #3F4D62;margin-top: 0.1rem;">{{ item.content }}</div>
                    </div>
                </div>
            </div> 
            <!-- <div style="position: relative;top:1rem;height: 5rem;">
                <div>
                    <div class="page-nextTitle">
                        <span>融合海量算法 </span>
                        <span style="margin-left: 0.3rem;"> 赋能千行万业</span>
                    </div>
                </div> 
                <div class="dashborad-card">
                    <div class="dashborad-card-item" v-for="(item,index) in introduceCards" :key="index">
                        <img :src="item.icon" alt="" style="width: 1rem;height: 1rem;" v-if="[1,3].includes(index)">
                        <div class="dashborad-card-item-content">
                            <div class="dashborad-card-item-title">{{ item.title }}</div>
                            <div class="dashborad-card-item-text">{{ item.text }}</div>
                        </div>
                        <img :src="item.icon" alt="" style="width: 1rem;height: 1rem;" v-if="[0,2].includes(index)">
                    </div>
                </div>   

            </div> -->
        </div>
        
        <!-- <div style="background:#f3f4f5">
            <div class="solutionTitle" :style="{ 'backgroundImage' : `url(${require('@/assets/images/home/service1.png')})`}">多种业务场景解决方案</div>
            <div class="page_wrap">
                <div class="dashborad-flex" style="height:4.2rem">
                    <div class="column-between">
                        <div>
                            <div @click="chooseSolution(item,index)" class="solutionItem" v-for="(item,index) in solutionList" :key="index" :class="{ 'active': clickIndex == index}">
                                <span>{{ item.name }}</span>
                                <div>
                                    <i class="el-icon-right" style="fontSize:0.2rem"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width:85%;position:relative;left:0.5rem">
                        <div class="solution-detail-wrap">
                            <div v-for="el in show.picList" :key="el.index" class="solution-detail-img" :style="{ 'backgroundImage' : `url(${el})`}"></div>
                        </div>
                        <div class="solution-describe">
                            <span style="color:#606266;font-size:0.16rem;font-weight:666;line-height:0.36rem">案例描述</span><br/>
                            {{ show.descirbe }}
                        </div>
                    </div>
                </div>
            </div>   
        </div> -->
        
        <!-- <div class="page_wrap">
            <div class="page_content">
                <p class="dashborad-flex-title" style="text-align:center;width:3.6rem;height:0.5rem;paddingTop:0.3rem;background-size: 100%" :style="{ 'backgroundImage' : `url(${require('@/assets/images/news.png')})`}">最新资讯</p>
                <div v-for="item in photoList" :key="item.index">
                    <photo-list :info='item' class="photo_list" :width="'1.6rem'" :height="'1.2rem'"></photo-list>
                </div>
                <div class="link-btn" style="text-align:center">
                    <el-button type="primary" style="width: 2rem" @click="$router.push('/news')">更多资讯<i class="el-icon-right"></i></el-button>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
/* eslint-disable */
import newsData from '@/assets/js/news.js'
import api from '@/api/api'
import photoList from './photoList.vue'
export default {
    components:{ photoList },
    created(){
        // this.photoList = newsData.newsList
        this.queryList()
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.show = this.solutionList[0]
    },
    data(){
        return{
            clickIndex:0,
            show:{},
            rightIcon:require('@/assets/images/right-white.png'),
            solutionList:[
                { name:'AI算力中心', url:'/transport', index:0, descirbe:'在云端业务上，智算中心是一个先进的大型基础设施，能够提供海量的智能计算、存储、网络通信能力；运营团队基于智算中心可以提供专业、普惠的智能计算应用服务；同时，通过多方引入生态企业，智算中心又将成为一个人工智能应用汇聚平台。因此在云端业务上，运营团队必须将算力、数据、算法、应用市场等核心要素通盘整合，通过高效、便捷、模块化的组合服务满足不同客户的定制化需求。',
                    picList:[require('@/assets/images/home/icon2-1.png'),require('@/assets/images/home/icon2-2.png'),require('@/assets/images/home/icon2-3.png')]
                },
                { name:'人工智能教育', url:'/solution', index:1, descirbe:'CCD光学瑕疵检测设备是南京智算中心面向高校提供的人工智能实训教具，帮助学生深入学习机器视觉、图像处理、深度学习方面的知识点，满足视觉感知和表面瑕疵智能检测需要，提升学生在人工智能方面的能力。',
                    picList:[require('@/assets/images/home/icon3-1.png'),require('@/assets/images/home/icon3-2.png'),require('@/assets/images/home/icon3-3.png')]
                },
                { name:'工业视觉检测', url:'/testing', index:2, descirbe:'南京智算中心工业视觉AI算法采用全链路打通数据采集、标注、训练和部署环境，采用深度学习和图像处理算法，进行全自动生产流程管理，自动识别产品图像中的瑕疵或故障，以达到大幅节省人力，提高产品生产效率及精度稳定性的效果。',
                    picList:[require('@/assets/images/home/icon4-1.png'),require('@/assets/images/home/icon4-2.png'),require('@/assets/images/home/icon4-3.png')]
                },
                { name:'交通车辆检测', url:'/testing', index:3, descirbe:'借助可视化融合各类监控系统，建立工具和工具、数据和数据的联动，直观展示全域交通状况。同时对接交通路网上包括车辆监控、智慧斑马线、交通信号灯、照明灯组等，实现设备故障实时监测、告警。',
                    picList:[require('@/assets/images/home/icon5-1.png'),require('@/assets/images/home/icon5-2.png'),require('@/assets/images/home/icon5-3.png')]
                },
            ],
            imglist:[
                { id:0, brand:require('@/assets/images/new_home/banner1-title1.png'),slogan:require('@/assets/images/new_home/banner1-titlesmall.png'),src:require('@/assets/images/new_home/banner1.png') },
                { id:1,brand:require('@/assets/images/new_home/banner2-title.png'), slogan:require('@/assets/images/new_home/banner2-titlesmall.png'), content:'<span>为响应国家工信部，国家发改委等四部门联合发布的《智能制造试点示范行动实施方案》，深化推动智能制<br />造产业应用，南京智算中心凭借长期以来的实践研发经验，基于南京智算中心强大稳定的运算环境，在工业<br/>视觉算法领域为广大制造企业及设备供应商提供普惠高效的解决方案！</span>', src:require('@/assets/images/new_home/banner2.png')},
                { id:2,brand:require('@/assets/images/new_home/banner3-title.png'), slogan:require('@/assets/images/new_home/banner3-titlesmall.png'), content:'<span>为了建设国产人工智能教育科研平台、普及国产智能计算平台框架，推动国产智能处理器在科学与工程研究项目中的广泛使用。智算中心运营团队从实际案例出发，研发出了完善的国产边缘智能教学套件，本套件包含了完整的软硬件系统、内容与服务。其中软硬件系统涵盖了云边一体的实验环境，内容包含了完整的《人工智能通识》《边缘智能实践》课程所需的教材教案、实验、考试素材，涵盖了人工智能应用课程培训全流程内容体系。</span>', src:require('@/assets/images/new_home/banner3.png')},
            ],
            introduces:[
                { url:require('@/assets/images/new_home/introduce1.png'), title:'强大的AI算力', content:'当前算力可达570Pops@INT16' },
                { url:require('@/assets/images/new_home/introduce2.png'), title:'7*24小时服务', content:'专业团队提供完善的服务' },
                { url:require('@/assets/images/new_home/introduce3.png'), title:'丰富的AI算法', content:'国内首屈一指的视觉算法生态平台' },
                { url:require('@/assets/images/new_home/introduce4.png'), title:'专业的研发思路', content:'建设、运营、应用三阶段统筹协同' },
            ],
            solutionImgList:[
                // require('@/assets/images/tu1.png'),
                require('@/assets/images/tu2.png'),
                require('@/assets/images/tu3.png'),
            ],
            introduceCards:[
                { url:require('@/assets/images/new_home/bgdiv1.png'), title:'AI算力中心', content:'赋能行业所需算法新型基础设施', icon:require('@/assets/images/new_home/cardIcon1.png'), text:'在云端业务上，智算中心是一个先进的大型基础设施，能够提供海量的智能计算、存储、网络通信能力；运营团队基于智算中心可以提供专业、普惠的智能计算应用服务；同时，通过多方引入生态企业，智算中心又将成为一个人工智能应用汇聚平台。' },
                { url:require('@/assets/images/new_home/bgdiv2.png'), title:'人工智能教育', content:'人工智能教育生态解决方案', icon:require('@/assets/images/new_home/cardIcon2.png'), text:'CCD光学瑕疵检测设备是南京智算中心面向高校提供的人工智能实训教具，帮助学生深入学习机器视觉、图像处理、深度学习方面的知识点，满足视觉感知和表面瑕疵智能检测需要，提升学生在人工智能方面的能力。' },
                { url:require('@/assets/images/new_home/bgdiv3.png'), title:'工业视觉检测', content:'工业生产AI检测解决方案', icon:require('@/assets/images/new_home/cardIcon3.png'), text:'南京智算中心工业视觉AI算法采用全链路打通数据采集、标注、训练和部署环境，采用深度学习和图像处理算法，进行全自动生产流程管理，自动识别产品图像中的瑕疵或故障，以达到大幅节省人力，提高产品生产效率及精度稳定性的效果。' },
                { url:require('@/assets/images/new_home/bgdiv4.png'), title:'交通车辆检测', content:'交通车辆行驶检测解决方案', icon:require('@/assets/images/new_home/cardIcon4.png'),text:'借助可视化融合各类监控系统，建立工具和工具、数据和数据的联动，直观展示全域交通状况。同时对接交通路网上包括车辆监控、智慧斑马线、交通信号灯、照明灯组等，实现设备故障实时监测、告警。' },
            ],
            photoList:[],
            nextwrapList:[
                { titleBig:'AI算力中心', titleSmall:'赋能行业所需算法新型基础设施', url:require('@/assets/images/home/icon1-1.png')},
                { titleBig:'人工智能教育', titleSmall:'人工智能教育生态解决方案', url:require('@/assets/images/home/icon1-2.png')},
                { titleBig:'工业视觉检测', titleSmall:'工业生产AI检测解决方案', url:require('@/assets/images/home/icon1-3.png')},
                { titleBig:'交通车辆检测', titleSmall:'交通车辆行驶检测解决方案', url:require('@/assets/images/home/icon1-4.png')},
            ],
        }
    },
    methods:{
        handleJump(e){
            if(e.id == 0){
                this.$router.push('/aicenter')
            }else if(e.id == 1){
                this.$router.push('/aiCase')
            }else{
                this.$router.push('/industryCases')
            }
        },
        chooseSolution(e,i){
            this.clickIndex = i
            this.show = e
        },
        queryList(){
             api.newslist({
                pageNum: 1,
                pageSize: 999
                }).then(res=>{
                 if(res.code==200 && res.data.list){
                   this.photoList=res.data.list.slice(0,8)
                 }
             })
         }
    }
}
</script>
<style lang="scss">
.text-center{
    text-align: center;
}
.background{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.home {
    .el-carousel__mask{
        background: #eff0f5;
    }
    .el-button--small{
        padding:0.09rem;
        font-size: 0.12rem;
    }
}
.imgHover:hover {
    transform: scale(1.1);
}
.imgHover {
    transition: transform 3s ease;
}
@keyframes animaRight
{
    0%   {left: 6rem}
    50%  {left: 3rem}
    100%  {left: 0px}
}
@keyframes animaLeft
{
    0%   {right: 6rem}
    50%  {right: 3rem}
    100%  {right: 0px}
}
.animationRight {
    position: relative;
    animation: animaRight 1s linear 1 alternate;
}
.animationLeft {
    position: relative;
    animation: animaLeft 1s linear 1 alternate;
}
</style>
<style lang="scss" scoped>

.nextTitle-wrap{
    width: 2.8rem;
    height: 2.8rem;
    position: relative;
    top:0.3rem;
}
.nextTitle-pic{
    width: 2.8rem;
    height: 1.8rem;
    border-radius: 0.2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.nextTitle-title-big,.nextTitle-title-small{
    text-align: center;
    line-height: 0.3rem;
}
.nextTitle-title-big{
    font-size: 0.16rem;
    color:#333;
    font-weight: 600;
}
.nextTitle-title-small{
    font-size: 0.14rem;
    color:#606266;
}
.page-nextTitle{
    font-size: 0.24rem;
    color:#1465E4;
    font-weight: 600;
    margin: 0 auto;
    letter-spacing: 4px;
    text-align: center;
    height: 0.6rem;
}
    .checkMore{
        z-index: 999;
        position: relative;
        bottom:0.5rem;
    }
    .banner-brand{
        position: absolute;
        top:1rem;
        z-index: 9999;
        color:#fff;
        width: 100%;
        margin-left: 3rem;
        .button{
            width: 1.1rem;
            height: 0.4rem;
            cursor: pointer;
            font-weight: 600;
            font-size: 0.14rem;
            margin-top: 0.4rem;
            font-family: '黑体';
            letter-spacing: 0.03rem;
        }
        .button:hover{
            box-shadow: 0px 0px 0.1rem #95b3da;
        }
        &-brand{
            width: 6.2rem;
            height: 0.7rem;
        }
        &-slogan{
            height: 0.26rem;
            position: relative;
            margin: 0.3rem 0;
        }
        &-content{
            font-size: 0.14rem;
            line-height: 0.22rem;
            color:#ebebebe5;
            font-weight: 500;
            width: 7rem;
            letter-spacing: 1px;
        }
    }
    .banner-div{
        position: absolute;
        top:1rem;
        z-index: 9999;
        color:#fff;
        width: 100%;
        left:10%;
        text-align: left;
        &-title{
            font-size: 0.4rem;
            margin-bottom:0.4rem;
        }
        &-middle{
            font-size: 0.22rem;
            margin-bottom:0.4rem;
            color:#ededed;
        }
        &-nextTitle{
            font-size: 0.16rem;
            margin:0.3rem auto;
            line-height: 0.26rem;
            color:#ecececdc;
        }
        
        .firstBtn{
            margin:0 auto;
            position: relative;
            left: -10%;
            top:2.4rem;
        }
    }
    .photo_list{
        width:44%;
        height:1.2rem;
        float:left;
        margin:0 3% 0.3rem 3%;
    }
    .photo_list:nth-of-type(2n){
        margin-right:0;
    }
    .column-center{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .column-between{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .dashborad{
        &-flex{
            width: 70%;
            max-width: 16rem;
            margin: 0 auto;
            background-size: 0.6rem auto;
            background-repeat: no-repeat;
            &-title{
                font-size: 0.34rem;
                font-weight: 500;
                color:#4c4c4d;
                margin:0.5rem auto;
            }
            display: flex;
            justify-content: space-between;
            height:6rem;
            margin:0.4rem 0;
            &-vedio{
                width: 52%;
                height: 4rem;
                margin-top:0.5rem;
            }
            &-text{
                width: 45%;
                text-align: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                &-bg{
                    width: 90%;
                    max-width: 16rem;
                    height:3.6rem;
                    margin:0 auto;
                    padding:0 0.24rem;
                    color:#332343;
                    font-size: 0.16rem;
                    text-align: left;
                    margin-left:15%;
                    &-title{
                        font-size: 0.32rem;
                        font-weight: 500;
                        color:#333;
                    }
                    &-content{
                        color:#909399;
                        width: 80%;
                        line-height: 0.36rem;
                    }
                }
            }
        }
        &-introduce{
            width: 12rem;
            height: 1.2rem;
            margin: 0 auto;
            z-index: 999;
            // position: absolute;
            // left: calc(50% - 3.26rem);
            // top:-0.4rem;
            // box-shadow: 1px 1px 6px 1px #ededed;
            &-item{
                width: 2.99rem;
                margin: 0.28rem 0.3rem;

            }
            &-main{
                position: relative;
                left: 0.12rem;
            }
        }
        &-card{
            &-item{
                &-content{
                    width: calc(100% - 1.2rem);
                }
                &-title{
                    font-size: 0.24rem;
                    color: #333;
                    font-weight: 600;
                    margin-bottom:0.16rem;
                }
                &-text{
                    font-size: 0.12rem;
                    color: #333;
                }
                width: calc(50% - 0.6rem);
                display: flex;
                justify-content: space-between;
                margin: 0.5rem 0;
            }
            &-item:nth-of-type(odd){
                margin-right: 0.2rem;
                text-align: right;
            }
            &-item:nth-of-type(even){
                margin-left: 0.2rem;
            }
            width: 13rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    @media only screen and (max-width: 1980px) {
        .dashborad_flex {
            width: 80%;
        }
    }
    .solutionItem{
        width: 1.8rem;
        height: 0.5rem;
        line-height: 0.5rem;
        margin:0.3rem auto;
        cursor: pointer;
        color: #909399;
        border: 1px solid #428ce0;
        border-radius: 0.1rem;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding:0 0.2rem;
        font-weight: 600;
    }
    .solutionItem:hover{
        color:#428ce0;
    }
    .link-btn{
        margin: 0.5rem auto;
    }
    .solutionTitle{
        text-align: center;
        font-size: 0.28rem;
        color:#333;
        position: relative;
        top:0.2rem;
        width: 4.5rem;
        height: 0.9rem;
        margin: 0 auto;
        line-height: 1.2rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .active{
        color: #428ce0;
    }
    .solution-detail-wrap{
        width:100%;
        display: flex;
        justify-content: space-between;
    }
    .solution-detail-img{
        width: 3.2rem;
        height: 2.4rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .solution-describe{
        background: #fff;
        border-top:2px solid #428ce0;
        border-radius: 0.05rem;
        padding:0.2rem 0.24rem;
        line-height: 0.24rem;
        color:#909399;
        margin-top: 0.4rem;
    }
</style>

